module.exports = {
  "404": "404",
  "USER_EXISTS": "USER_EXISTS",
  "USER_DOESNT_EXIST": "USER_DOESNT_EXIST",
  "USER_ALREADY_INVITED": "USER_ALREADY_INVITED",
  "LOGIN_FAIL": "LOGIN_FAIL",
  "VALIDATION": "VALIDATION",
  "RESET_TOKEN_INVALID": "RESET_TOKEN_INVALID",
  "VERIFY_TOKEN_INVALID": "VERIFY_TOKEN_INVALID",
  "USER_ALREADY_VERIFIED": "USER_ALREADY_VERIFIED",
  "RESOURCE_NOT_FOUND": "RESOURCE_NOT_FOUND",
  "ACCESS_DENIED": "ACCESS_DENIED",
  "REGISTER_BEFORE_ACCEPT": "REGISTER_BEFORE_ACCEPT"
};