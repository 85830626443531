import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
import {ResendVerifyButton, ResetPasswordForm} from '../../components/session';
import { verifyAccount } from "../../api/SessionApi";
import queryString from 'query-string';
import * as ERRORS from '../../api/common/errors'
import * as Paths from '../../constants/Paths'
import LayoutA from "../../layouts/LayoutA";

class PleaseVerify extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: true,
      verified: false,
      nouser: false,
      invalidtoken: false,
      malformedurl: false,
      alreadyverified: false,
      error: null
    }

  }

  componentDidMount() {
    this.verify();
  }

  verify() {

    var query = queryString.parse(window.location.search);

    if (_.isString(query.token) && _.isString(query.userId)) {

      verifyAccount(query.token, query.userId)
        .then(() => {
          this.resetErrorState();
          this.setState({processing: false, verified: true})
        })
        .catch((e) => {

          this.setState({processing: false, verified: false});

          switch (e.errorCode) {
            case ERRORS.USER_ALREADY_VERIFIED:
              this.setState({ alreadyverified: true });
              break;
            case ERRORS.USER_DOESNT_EXIST:
              this.setState({ nouser: true });
              break;
            case ERRORS.VERIFY_TOKEN_INVALID:
              this.setState({ invalidtoken: true });
              break;
            default:
              this.setState({ error: e.message })
          }
        })

    } else {
      this.setState({processing: false, malformedurl: true})
    }

  }

  resetErrorState() {
    this.setState({
      verified: false,
      alreadyverified: false,
      nouser: false,
      invalidtoken: false,
      malformedurl: false,
      error: null
    })
  }

  render() {

    const { processing, verified, nouser, invalidtoken, alreadyverified } = this.state;

    return (

      <LayoutA>
        <div className="LoginTemplate">
          <div className="LoginTemplate-floatingform">
            {processing ? (
              <div>processing...</div>
            ) : verified ? (
              <div className="t-center">
                <div className="f2 s3">Verification successful!</div>
                <Link className="f3" to={Paths.DB_USER_HOME}>
                  <span className="sr2">To dashboard</span>
                  <span className="pt-icon-standard pt-icon-arrow-right" />
                </Link>
              </div>
            ) : alreadyverified ? (
              <div className="t-center">
                <div className="f2 s3">You are already verified.</div>
                <Link className="f3" to={Paths.DB_USER_HOME}>
                  <span className="sr2">To dashboard</span>
                  <span className="pt-icon-standard pt-icon-arrow-right" />
                </Link>
              </div>
            ) : nouser ? (
              <div className="t-center">
                <div className="f2 s3">That user doesn't exist.</div>
                <Link className="f3" to={Paths.REGISTER}>
                  <span className="sr2">Please Register</span>
                  <span className="pt-icon-standard pt-icon-arrow-right" />
                </Link>
              </div>
            ) : invalidtoken ? (
              <div className="t-center">
                <div className="f2 s3">You're token is invalid.</div>
                <div className="s2">
                  <ResendVerifyButton />
                </div>
                <Link to={Paths.LOGIN} className="f4">
                  <span className="pt-icon-standard pt-icon-arrow-left" />Back to login
                </Link>
              </div>
            )  : (
              <div className="t-center">
                <div className="f2 s3">Something went wrong.</div>
                <div className="s2">
                  <ResendVerifyButton />
                </div>
                <Link to={Paths.LOGIN} className="f4">
                  <span className="pt-icon-standard pt-icon-arrow-left" />Back to login
                </Link>
              </div>
            )}
          </div>
        </div>
      </LayoutA>
    );
  }
}

export default PleaseVerify;